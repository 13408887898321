* {
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

figure {
  margin: 0;
}

figure img {
  display: block;
}

.header {
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header img {
  height: 64px;
}

.text-page {
  width: 100%;
  max-width: 1366px !important;
  margin: auto;
  padding: 0 30px;
}

.text-page h1 {
  font-size: 1.625rem;
  color: #ff5747;
  margin: 20px 0;
  text-align: center;
  font-weight: 500;
}

.text-page h2 {
  font-size: 1.125rem;
  color: #ff5747;
  font-weight: 700;
  line-height: 27px;
  margin: 25px 0 0 0;
}

.text-page p {
  font-weight: 300;
  margin-bottom: 15px;
  line-height: 29px;
  display: block;
  text-align: justify;
}

.text-page li {
  font-weight: 300;
  margin-bottom: 15px;
  line-height: 29px;
  text-align: justify;
}

.text-center {
  text-align: center !important;
}

tspan {
  font-size: 13px;
}
